<template>
  <div class="contact-home">
    <div style="width: 100%;padding: 60px 120px;box-sizing: border-box">
      <div class="flex-row" style="align-items: center">
        <img src="../assets/go_home.png" style="width: 44px;height: 44px;cursor: pointer" @click="goHome">
        <el-select v-model="select_year" placeholder="Select year" style="margin-left: 40px">
          <el-option value="23-24" label="23-24"/>
        </el-select>
      </div>
      <div style="margin-top: 20px;width: 100%;padding-left:84px;box-sizing: border-box">
        <div class="contact-grid">
          <div v-for="(item,index) in dataList" :key="index" class="contact-item">
            <div class="flex-row">
              <p class="student-name" style="flex: 1;padding-right: 15px;box-sizing: border-box">
                {{ item.student.student_lastName + item.student.student_givenName }}</p>
              <p class="subject-name">{{ item.subject_name }}</p>
            </div>
            <div class="flex-row-center" style="margin-top: 25px">
              <div style="flex: 1">
                <p class="item-content-title">Accuracy</p>
                <p class="item-content-value">{{ formatAccuracy(item) }}</p>
              </div>
              <div style="flex: 1">
                <p class="item-content-title">Answers Wrong</p>
                <p class="item-content-value">{{ item.finished - item.corrects }}</p>
              </div>
              <div style="flex: 1">
                <p class="item-content-title">Collection</p>
                <p class="item-content-value">{{ item.collections }}</p>
              </div>
            </div>
            <div style="margin-top: 25px" class="flex-row">
              <p class="item-content-title" style="padding-right: 15px;box-sizing: border-box">Progress</p>
              <el-progress style="flex: 1" :stroke-width="26" define-back-color="#E4E8F5"
                           :percentage="formatProgress(item)"
                           text-color="#538AFF"></el-progress>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {getOGStudentSubjects} from '../api/fbla'
import {getUserId} from "../utils/store";
import '../assets/common/common.css'
import '../assets/common/font.css'
import {Loading} from "element-ui";

export default {
  name: "ContactHome",
  data() {
    return {
      select_year: '23-24',
      user_id: getUserId(),
      dataList: []
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    formatProgress(item) {
      let finished = item.finished
      let total = item.total
      if (total > 0) {
        let progress = Math.round((finished / total) * 100)
        return progress
      }
      return 0
    },
    formatAccuracy(item) {
      let finished = item.finished
      let corrects = item.corrects
      if (finished <= 0) {
        return "0%"
      }
      let temp = Math.round((corrects / finished) * 100)
      return temp + "%"
    },
    fetchData() {
      this.showLoadingView()
      getOGStudentSubjects(this.user_id).then((res) => {
        this.dataList = res.data.data
        this.closeLoading()
      })
    },
    showLoadingView() {
      this.closeLoading()
      this.loadingInstance = Loading.service({
        background: 'rgba(0, 0, 0, 0.8)',
        spinner: 'el-icon-loading',
        text: 'Loading'
      });
    },
    closeLoading() {
      if (this.loadingInstance) {
        this.loadingInstance.close()
      }
    },
    goHome() {
      this.$router.go(-1)
    }
  }
}
</script>

<style scoped>

.contact-home {
  position: relative;
  width: 100%;
  margin: 0 auto;
  max-width: 1920px;
  height: 92vh;
  overflow-y: auto;
}

.contact-grid {
  margin-top: 30px;
  width: 100%;
  display: grid;
  grid-row-gap: 24px;
  grid-column-gap: 32px;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto;
}

.contact-item {
  background: #F2F6FF;
  box-shadow: 0px 3px 6px 1px rgba(9, 46, 127, 0.08);
  border-radius: 10px 10px 10px 10px;
  padding: 24px 32px;
  box-sizing: border-box;
}

.student-name {
  font-size: 20px;
  font-family: AlibabaPuHuiTi-2-75-SemiBold;
  font-weight: 600;
  color: #666666;
}

.subject-name {
  font-size: 20px;
  font-family: AlibabaPuHuiTi-2-75-SemiBold;
  font-weight: 600;
  line-height: 38px;
  color: #092E7F;
}

.item-content-title {
  font-size: 16px;
  font-family: AlibabaPuHuiTi-2-65-Medium;
  font-weight: 500;
  color: #666666;
}

.item-content-value {
  margin-top: 10px;
  font-size: 28px;
  font-family: AlibabaPuHuiTi-2-75-SemiBold;
  font-weight: 500;
  color: #092E7F;
}

/deep/ .el-progress-bar__inner {
  height: 100%;
  background: linear-gradient(270deg, #538AFF 0%, #8EB3FF 100%);
}
</style>
